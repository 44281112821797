import React, { useState, useEffect } from 'react';

const UpdateTitle = () => {
  // Load the initial title from localStorage, or default to 'Page1'
  const [title, setTitle] = useState(localStorage.getItem('pageTitle') || 'Page1');
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState('');

  // Handle the click event to show the input field
  const handleShowInput = () => {
    setShowInput(true);
  };

  // Handle the confirm button click to update the title
  const handleConfirm = () => {
    if (inputValue.trim() !== '') {
      setTitle(inputValue);
      localStorage.setItem('pageTitle', inputValue); // Save the updated title to localStorage
      setShowInput(false);
      setInputValue('');
    }
  };

  return (
    <div>
      <h1>{title}</h1>
      <button onClick={handleShowInput}>Edit Title</button>
      {showInput && (
        <div>
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <button onClick={handleConfirm}>Confirm</button>
        </div>
      )}
    </div>
  );
};

export default UpdateTitle;