// Helen.js
import './Helen.css';
import React from 'react';

function Helen() {
  return (
    <div className="Helen">
        <header className="Helen-header">
            <p>
                <span style={{ color: 'white' }}>My Helen </span>
                <span style={{ color: 'red' }}>&#x2764;</span>
            </p>
        </header>
    </div>
  );
}

export default Helen;