// Home.js
import './Home.css';
import React from 'react';

function Home() {
  return (
    <div className="Home">
        <header className="Home-header">
            <p>
                Welcome to the Happytinybunny.com :D
            </p>
        </header>
    </div>
  );
}

export default Home;