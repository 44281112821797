// Photo.js
import React from 'react';
import image from './profile.png'; // Tell Webpack this JS file uses this image

function TargetPhoto() {
    return (
      <div>
        <h1>Target Photo Page</h1>
        <img src={image} alt="Target Photo" />
      </div>
    );
}
  
export default TargetPhoto;