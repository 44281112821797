import React, { useEffect, useState } from "react";

const DataDisplay = () => {
  const [data, setData] = useState(null);  // State to store the API response
  const [loading, setLoading] = useState(true);  // Loading state
  const [error, setError] = useState(null);  // Error state

  useEffect(() => {
    // Fetch data from the API when the component mounts
    fetch("https://meowfacts.herokuapp.com/")  // Replace with your API URL
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();  // Parse JSON response
      })
      .then((json) => {
        setData(json);  // Set data from API response
        setLoading(false);  // Set loading state to false
      })
      .catch((error) => {
        setError(error);  // Set error state if an error occurs
        setLoading(false);
      });
  }, []);  // Empty array to ensure the effect runs only once on component mount

  // Render loading, error, or data
  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div>
      <h1>Data from API</h1>
      <pre>{JSON.stringify(data, null, 2)}</pre>  {/* Display the data */}
    </div>
  );
};

export default DataDisplay;