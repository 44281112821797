// PayPalLayoff.js
import './PayPalLayoff.css';
import React from 'react';

function PayPalLayoff() {
  return (
    // Display a title and a list of links to articles
    <div className="PayPalLayoff">
      <h1>重庆！</h1>
    </div>
  );
}

export default PayPalLayoff;